import React from "react"
import Lottie from "react-lottie"
import animationData from "../../lottie/logo.json"
import { Link } from "gatsby"

const Logo = ({children, isH1=false}) => (
  isH1 ? <h1>{children}</h1> : <div>{children}</div>
)

const Header = ({isH1}) => {
  const logoOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div>
      <header className="pt-12 text-center">
        <Logo isH1={isH1}>
          <Link to={`/`}>
            <Lottie options={logoOptions} width={200} height={77} />
            <span className="italic tracking-widest text-xs">タカハシ</span>
          </Link>
        </Logo>
      </header>
    </div>
  )
}

export default Header
