import React from "react"

import Header from './header'
import Aside  from './aside'
import Footer from './footer'

import '@fontsource/montserrat/latin-400.css'
import '@fontsource/montserrat/latin-700.css'
import '@fontsource/noto-sans-jp/300.css'
import '@fontsource/noto-sans-jp/700.css'
import "./tailwind.css"

const Layout = ({ children, isH1 }) => (
  <div className="font-body antialiased text-gray-700 leading-extra-relaxed">
    <Header isH1={isH1} />
      { children }
    <Aside />
    <Footer />
  </div>
)

export default Layout
