import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"



const ListItem = ({listLabel, linkTo, count}) => (
  <li>
    <Link to={linkTo} className="block py-2 px-6 border-t border-gray-300 hover:text-gray-500">
      {listLabel}<span className="text-gray-400">（{count}）</span>
    </Link>
  </li>
)
const ListCard = ({listLabel, subLabel, linkTo, href}) => {
  const inner = <>
    {listLabel}
    <span className="block text-xs text-gray-400 italic">―― {subLabel}</span>
  </>
  return (
    <li className="inline-block px-4 py-5 text-sm">
      { linkTo
        ? <Link className="block mx-auto hover:text-gray-500" to={linkTo}>{inner}</Link>
        : <a href={href} className="block mx-auto hover:text-gray-500" target="_brank">{inner}</a>
      }
    </li>
  )
}

const Aside = () => {
  const { posts, site } = useStaticQuery(graphql`
    query {
      posts: allMdx(
        filter: {frontmatter: {
          draft: {eq: false}
        }}
      ) {
        edges {
          node {
            slug
          }
        }
      }
      site {
        siteMetadata {
          categories {
            label
            slug
          }
        }
      }
    }
  `)
  const catsMeta = site.siteMetadata.categories
  let catCounter = {}
  posts.edges.forEach( ( { node } ) => {
    const catSlug = node.slug.split( '/' )[0]
    if ( catSlug in catCounter ) {
      catCounter[ catSlug ].totalCount++
    } else  {
      catCounter[ catSlug ] = { totalCount : 1 }
    }
  } )

  return (
    <aside className="max-w-sm md:w-full md:max-w-screen-lg mx-auto pt-32 px-4">
      <section className="inline-block w-full md:w-5/12 pt-32 lg:px-10 md:px-4 md:align-top">
        <ul className="pt-8 overflow-hidden border-b border-gray-300 text-sm" id="categories">
          { catsMeta.map( cat => {
            const catSlug = cat.slug
            return ( catSlug in catCounter &&
              <ListItem
                listLabel={cat.label}
                linkTo={`/${catSlug}/`}
                count={catCounter[ catSlug ].totalCount} 
                key={catSlug}
              />
            )
          } ) }
        </ul>
      </section>
      <section className="inline-block w-full md:w-6/12 pt-32 p-0 md:pl-6 md:align-top">
        <ul className="flex flex-row flex-wrap pt-8 leading-tight" id="site-contents">
          <ListCard listLabel="トップページ" subLabel="Top" linkTo="/" />
          <ListCard listLabel="すべての記事" subLabel="Archive" linkTo="/archives/" />
          <ListCard listLabel="お問い合わせ" subLabel="Contact" linkTo="/contact/" />
        </ul>
      </section>
    </aside>
  )
}

export default Aside
