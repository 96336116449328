import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import {
  ldjsonMain,
  ldjsonArticle,
  ldjsonBreadcrumbListItem,
  ldjsonBreadcrumbList,
  ldjsonItemListItem,
  ldjsonItemList,
} from './ldjson.js'



const Seo = ({
  pageTitle,
  pageDescription,
  pageUrl,
  pageImageUrl,
  pageImageWidth,
  pageImageHeight,
  pageDatePublished,
  pageDateModified,
  isArticleLdJSON=false,
  isMainLdJSON=false,
  isNoIndex=false,
  isNoFollow=false,
  postList=[],
  breadcrumbList=[],
  hreflangList=[],
}) => {
  const data = useStaticQuery( graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
          datePublished
          lang
          locale
          fbAppId
          siteAuthor {
            name
            twitterUrl
            instagramUrl
          }
        }
        buildTime(formatString: "yyyy-MM-DD")
      }
      thumb: file(relativePath: {eq: "thumb.png"}) {
        childImageSharp {
          original {
            height
            src
            width
          }
        }
      }
      logo: file(relativePath: {eq: "icon.png"}) {
        childImageSharp {
          original {
            height
            src
            width
          }
        }
      }
    }
  `)
  const existPageItems = pageTitle && pageDescription && pageUrl ? true : false
  const exitsPageImage = pageImageUrl && pageImageWidth && pageImageHeight ? true : false

  // サイト関連情報をまとめる
  const site = {
    lang: data.site.siteMetadata.lang,
    locale: data.site.siteMetadata.locale,
    title: data.site.siteMetadata.title,
    description: data.site.siteMetadata.description,
    url: data.site.siteMetadata.siteUrl,
    author: data.site.siteMetadata.siteAuthor,
    fbAppId: data.site.siteMetadata.fbAppId,
    date: {
      published: data.site.siteMetadata.datePublished,
      modified: data.site.buildTime,
    }
  }
  site.image = {
    url: `${site.url}${data.thumb.childImageSharp.original.src}`,
    width: data.thumb.childImageSharp.original.width,
    height: data.thumb.childImageSharp.original.height,
  }
  site.logo = {
    url: `${site.url}${data.logo.childImageSharp.original.src}`,
    width: data.logo.childImageSharp.original.width,
    height: data.logo.childImageSharp.original.heigh,
  }

  // ページ関連情報をまとめる
  const page = existPageItems ? {
    title: pageTitle,
    description: pageDescription,
    url: pageUrl.indexOf(site.url)===-1 ? `${site.url}${pageUrl}` : pageUrl,
    date: {
      published: pageDatePublished || site.date.published,
      modified: pageDateModified || site.date.buildTime,
    }
  } : {}
  if (existPageItems && exitsPageImage) {
    page.image = {
      url: pageImageUrl.indexOf(site.url)===-1 ? `${site.url}${pageImageUrl}` : pageImageUrl,
      width: pageImageWidth,
      height: pageImageHeight,
    }
  }

  // OGP情報をまとめる
  const og = {
    title: !existPageItems
      ? site.title
      : page.title.indexOf(site.title)===-1
        ? `${page.title} - ${site.title}`
        : page.title,
    description: !existPageItems ? site.description : page.description,
    url: !existPageItems ? site.url : page.url,
    image: exitsPageImage ?
      {
        url: page.image.url,
        width: page.image.width,
        height: page.image.height,
      } : {
        url: site.image.url,
        width: site.image.width,
        height: site.image.height,
      },
  }

  // 検索結果用の構造化データを作成
  const jsonContent = []
  isMainLdJSON && jsonContent.push(
    ldjsonMain({
      siteTitle: site.title,
      description: site.description,
      siteUrl: site.url,
      siteImageUrl: site.image.url,
      siteImageWidth: site.image.width,
      siteImageHeight: site.image.height,
      authorName: site.author.name,
      logoImageUrl: site.logo.url,
      logoImageWidth: site.logo.width,
      logoImageHeight: site.logo.height,
      datePublished: site.date.published,
      dateModified: site.date.modified,
    })
  )
  isArticleLdJSON && jsonContent.push(
    ldjsonArticle({
      pageTitle: page.title,
      description: page.description,
      pageUrl: page.url,
      pageImageUrl: page.image && page.image.url,
      pageImageWidth: page.image && page.image.width,
      pageImageHeight: page.image && page.image.height,
      authorName: site.author.name,
      siteTitle: site.title,
      logoImageUrl: site.logo.url,
      logoImageWidth: site.logo.width,
      logoImageHeight: site.logo.height,
      datePublished: page.date.published,
      dateModified: page.date.modified,
    })
  )
  breadcrumbList.length > 0 && jsonContent.push(
    ldjsonBreadcrumbList({
      itemsArray: [
        breadcrumbList.map(breadcrumb => (
          ldjsonBreadcrumbListItem({
            position: breadcrumb.position,
            url: breadcrumb.url,
            name: breadcrumb.name,
          })
        ))
      ],
      description: page.description
    })
  )
  postList.length > 0 && jsonContent.push(
    ldjsonItemList({
      itemsArray: postList.map(post => (
        ldjsonItemListItem({
          position: post.position,
          url: `${site.url}${post.url}`,
        })
      )),
      description: ""
    })
  )

  // 出力する
  return (
    <Helmet>
      <html lang={site.lang}></html>
      <title>{og.title}</title>
      <meta name="description" content={og.description}/>
      <link rel="canonical" href={og.url} />
      {hreflangList.length > 0 && hreflangList.map( hreflang => (
        <link rel="alternate" hreflang={hreflang.lang} href={hreflang.url} />
      ))}
      {isNoIndex && <meta name="robots" content="noindex" />}
      {isNoFollow && <meta name="robots" content="nofollow" />}
      <meta property="og:site_name" content={site.title} />
      <meta property="og:title" content={og.title} />
      <meta property="og:description" content={og.description} />
      <meta property="og:url" content={og.url} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={site.locale} />
      {site.fbAppId && <meta property="fb:app_id" content={site.fbAppId} />}
      <meta property="og:image" content={og.image.url} />
      <meta property="og:image:width" content={og.image.width} />
      <meta property="og:image:height" content={og.image.height} />
      <meta property="twitter:card" content="summary_large_image" />
      <script type="application/ld+json">
        {JSON.stringify(jsonContent)}
      </script>
      <meta name="google-site-verification" content="6-aNQR36M1MnMomLBA4N5wTq36Zm6CHBOX5I0h-8lYw" />
    </Helmet>
  )
}

export default Seo
