// logo image 縦幅60pxで横幅600pxにおさまるもの
export const ldjsonMain = ({
  siteTitle,
  description,
  siteUrl,
  siteImageUrl,
  siteImageWidth,
  siteImageHeight,
  authorName,
  logoImageUrl,
  logoImageWidth,
  logoImageHeight,
  datePublished,
  dateModified,
}) => {
  return {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": siteTitle,
    "headline": siteTitle,
    "description": description,
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": siteUrl
    },
    "image": {
      "@type": "ImageObject",
      "url": siteImageUrl, //横幅1200px以上の画像とのこと
      "height": siteImageHeight,
      "width": siteImageWidth
    },
    "author": {
      "@type": "Person",
      "name": authorName,
    },
    "publisher": {
      "@type": "Organization",
      "name": siteTitle,
      "logo": {
        "@type": "ImageObject",
        "url": logoImageUrl, //縦幅60pxで横幅600pxにおさまるものがよいとのこと,
        "width": logoImageWidth,
        "height": logoImageHeight
      }
    },
    "datePublished": datePublished,
    "dateModified": dateModified
  }
}

// OGP画像(横幅696px以上の1×1の画像URL)
// logo image 縦幅60pxで横幅600pxにおさまるもの
export const ldjsonArticle = ({
  pageTitle,
  description,
  pageUrl,
  pageImageUrl,
  pageImageWidth,
  pageImageHeight,
  authorName,
  siteTitle,
  logoImageUrl,
  logoImageWidth,
  logoImageHeight,
  datePublished,
  dateModified
}) => {
  return {
    "@context": "http://schema.org",
    "@type": "Article",
    "name": pageTitle,
    "headline": pageTitle,
    "description": description,
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": pageUrl
    },
    "image": {
      "@type": "ImageObject",
      "url": pageImageUrl, //横幅1200px以上の画像とのこと
      "height": pageImageHeight,
      "width": pageImageWidth
    },
    "author": {
      "@type": "Person",
      "name": authorName
    },
    "publisher": {
      "@type": "Organization",
      "name": siteTitle,
      "logo": {
        "@type": "ImageObject",
        "url": logoImageUrl, //縦幅60pxで横幅600pxにおさまるものがよいとのこと,
        "width": logoImageWidth,
        "height": logoImageHeight
      }
    },
    "datePublished": datePublished,
    "dateModified": dateModified
  }
}

export const ldjsonBreadcrumbListItem = ({
  position,
  url,
  name
}) => {
  return {
    "@type": "ListItem",
    "position": position,
    "name": name,
    "item": url,
  }
}

export const ldjsonBreadcrumbList = ({
  itemsArray,
  description
}) => {
  return {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    "description": description,
    "itemListElement": itemsArray
  }
}

export const ldjsonItemListItem = ({
  position,
  url
}) => {
  return {
    "@type": "ListItem",
    "position": position,
    "url": url
  }
}

export const ldjsonItemList = ({
  itemsArray
}) => {
  return {
    "@context": "http://schema.org",
    "@type": "ItemList",
    "itemListElement": itemsArray
  }
}
